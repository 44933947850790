import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>Arriba un curs ultra intensiu per preparar la prova de català C1</p>
                <p>Us donarem accés a la plataforma 2 mesos.</p>
                <p>Tot el material es auto corregible i didàctic.</p>
                <p>Estàs preparat?</p>
                <p className="text-xl uppercase">
                    <strong>El curs</strong>
                </p>
                <p>Et proposem un curs intensiu preparatori per aprovar l'examen de català C1.</p>
                <p>
                    Amb aquest curs consolidaràs la normativa del català, expressar-te oralment i
                    per escrit utilitzant un repertori d'estructures ric i divers, i produir textos
                    adequats i ben estructurats sobre temes complexos dels àmbits social, acadèmic i
                    professional.
                </p>
                <p>
                    El curs està basat en el patró de les últimes convocatòries de català nivell C1.
                </p>
                <p className="text-xl uppercase">
                    <strong>Per a qui és el curs?</strong>
                </p>
                <p>
                    A opositors que han de superar la prova de nivell C1 de català, a persones en
                    general que es presentin a qualsevol prova de català nivell C1.
                </p>
                <p className="text-xl uppercase">
                    <strong>Format?</strong>
                </p>
                <p>
                    Intensiu de 2 mesos de duració, temps que estimen per assolir tots els
                    coneixements necessaris per aprovar l'examen
                </p>
                <p className="text-xl uppercase border-b-2">
                    <strong>Mecànica del curs?</strong>
                </p>
                <p>L'alumne pot iniciar el curs en qualsevol data.</p>
                <p>Des del moment de la matriculació s'activen els 60 dies de duració.</p>
                <p>
                    Cada alumne disposa del temps que necessiti per fer tot el curs amb la finalitat
                    de millorar les parts de l'examen oral i escrita.
                </p>
                <p>
                    Tots els exercicis són auto corregibles, vídeos explicatius on l'alumne
                    practicarà autònomament sense cap tutor.
                </p>
                <p className="text-xl uppercase">
                    <strong>Contingut del curs?</strong>
                </p>
                <ul className="space-y-5">
                    <li>Presentació del curs i 10 unitats formatives</li>
                    <li>
                        <strong>U.F. 1. Tipologia de redaccions: (28 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1. Les tipologies de les redaccions... duració 2,19 min</li>
                        <li>Vídeo 2. Text descriptiu... duració 1,34 min</li>
                        <li>Vídeo 3. Text expositiu... duració 1,42 min</li>
                        <li>Vídeo 4. Text informatiu... duració 1,09 min</li>
                        <li>Vídeo 5. Text instructiu... duració 1,39 min</li>
                        <li>Vídeo 6. Text narratiu... duració 1,09 min</li>
                        <li>Vídeo 7. Text retòric... duració 1,15 min</li>
                        <li>Vídeo 8. Text carta... duració 2,02 min</li>
                        <li>Vídeo 9. Text correu electrònic... duració 2,06 min</li>
                        <li>Vídeo 10. Text article d'opinió... duració 2,32 min</li>
                        <li>Pòdcast de reforç... duració 10,31 min</li>
                        <li>Fitxa amb idees força</li>
                        <li>Test de 15 preguntes autocorregibles</li>
                    </ul>
                    <li>
                        <strong>U.F. 2. Omple els buits: (22 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1 Connectors... duració 3,11 min</li>
                        <li>Vídeo 2 Connectors... duració 4,25 min</li>
                        <li>Vídeo 3 Exercicis... duració 5,48 min</li>
                        <li>Pòdcast de reforç... duració 8,30 min</li>
                        <li>Teoria ser/estar</li>
                        <li>Exercicis PDF ser/estar</li>
                        <li>Fitxa amb idees força</li>
                        <li>Exercici omplir els buits</li>
                        <li>Test de 15 preguntes autocorregibles</li>
                    </ul>
                    <li>
                        <strong>U.F. 3. Derivació i adjectius: (16,25 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1 Derivació... duració 3,12 min</li>
                        <li>Vídeo 2 Derivació... duració 4,03 min</li>
                        <li>Pòdcast de reforç... duració 5,08 min</li>
                        <li>Vídeo 3 Adjectius... duració 4,02 min</li>
                        <li>Exercicis amb adjectius A</li>
                        <li>Exercicis amb adjectius B</li>
                        <li>Exercicis amb adjectius C</li>
                        <li>Fitxa amb idees força</li>
                        <li>Test de 15 preguntes autocorregibles</li>
                    </ul>
                    <li>
                        <strong>U.F. 4. Frases amb significat similar: (18 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1 Preposicions... duració 5,07 min</li>
                        <li>Vídeo 2 Pronoms relatius (2)... duració 6,14 min</li>
                        <li>Vídeo 3 Exemples... duració 2,35 min</li>
                        <li>Pòdcast de reforç... duració 3,17 min</li>
                        <li>Exercicis de preposicions</li>
                        <li>Exercicis de preposicions (2)</li>
                        <li>Fitxa amb idees força</li>
                        <li>Test de 15 preguntes autocorregibles</li>
                    </ul>
                    <li>
                        <strong>U.F. 5. Barbarismes: (7,56 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1 Barbarismes... duració 1,56 min</li>
                        <li>Vídeo 1 Barbarismes... duració 3,19 min</li>
                        <li>Pòdcast de reforç... duració 2,19 min</li>
                        <li>Fitxa amb idees força</li>
                        <li>Test de 15 preguntes autocorregibles</li>
                    </ul>
                    <li>
                        <strong>U.F. 6. Frases fetes: (7,23 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1 Explicació... duració 2,52 min</li>
                        <li>Vídeo 2 Top 10 Frases fetes... duració 2,17 min</li>
                        <li>Pòdcast de reforç... duració 3,05 min</li>
                        <li>Fitxa amb idees força</li>
                        <li>Test de 15 preguntes autocorregibles</li>
                    </ul>
                    <li>
                        <strong>U.F. 7. Regles d'accentuació: (22 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1 Preàmbul... duració 1,42 min</li>
                        <li>Vídeo 2 Accentuació... duració 3,24 min</li>
                        <li>Vídeo 3 Monosíl·labs... duració 3,16 min</li>
                        <li>Pòdcast de reforç... duració 2,38 min</li>
                        <li>Vídeo 4 Perquè/Per què/Per a què... duració 5,22 min</li>
                        <li>Exercicis Per què/Perquè/Per a què</li>
                        <li>Vídeo 5 Dièresi... duració 2,20 min</li>
                        <li>Exercicis Dièresi</li>
                        <li>Vídeo 6 Diftongs i Hiats... duració 3,21 min</li>
                        <li>Exercicis Diftongs i Hiats</li>
                        <li>Fitxa amb idees força</li>
                        <li>Test de 15 preguntes autocorregibles</li>
                    </ul>
                    <li>
                        <strong>U.F. 8. Lectura: (8,15 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1 Lectura 1... duració 2,03 min</li>
                        <li>Vídeo 2 Lectura 2... duració 1,19 min</li>
                        <li>Vídeo 3 Lectura 3... duració 1,52 min</li>
                        <li>Pòdcast de reforç... duració 2,55 min</li>
                        <li>Fitxa amb idees força</li>
                        <li>Test de 15 preguntes autocorregibles</li>
                    </ul>
                    <li>
                        <strong>U.F. 9. Descripció: (11,50 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1 Explicació... duració 2,54 min</li>
                        <li>Vídeo 2 Exemple... duració 2,19 min</li>
                        <li>Vídeo 3 Exemple (2)... duració 4,14 min</li>
                        <li>Pòdcast de reforç... duració 2,05 min</li>
                        <li>Fitxa amb idees força</li>
                        <li>Test de 15 preguntes autocorregibles</li>
                    </ul>
                    <li>
                        <strong>U.F. 10. Repàs: (16,50 min aprox)</strong>
                    </li>
                    <ul>
                        <li>Vídeo 1 Repàs... duració 12,44 min</li>
                        <li>Pòdcast de reforç... duració 4,07 min</li>
                        <li>Fitxa amb idees força</li>
                        <li>Test de 30 preguntes autocorregibles</li>
                        <li>Examen final (A)</li>
                        <li>Examen final (B)</li>
                        <li>Examen final (C)</li>
                        <li>Plantilla examen oficial</li>
                    </ul>
                </ul>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/wOduVIE2mgg "
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate item={item} title="">
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>
                    Curs de Preparació per l'Examen de Català Nivell C1 (3 hores de vídeos)
                </Title>
                <Text>
                    Arriba un curs ultra intensiu per preparar la prova de català C1
                    <br />
                    <br />
                    Us donarem accés a la plataforma 2 mesos.
                    <br />
                    <br />
                    Tot el material es auto corregible i didàctic.
                    <br />
                    <br />
                    Estàs preparat?
                    <br />
                    <br />
                    <strong>El curs</strong>
                    <br />
                    <br />
                    Et proposem un curs intensiu preparatori per aprovar l'examen de català C1.
                    <br />
                    <br />
                    Amb aquest curs consolidaràs la normativa del català, expressar-te oralment i
                    per escrit utilitzant un repertori d'estructures ric i divers, i produir textos
                    adequats i ben estructurats sobre temes complexos dels àmbits social, acadèmic i
                    professional.
                    <br />
                    <br />
                    El curs està basat en el patró de les últimes convocatòries de català nivell C1.
                    <br />
                    <br />
                    <strong>Per a qui és el curs?</strong>
                    <br />
                    <br />
                    A opositors que han de superar la prova de nivell C1 de català, a persones en
                    general que es presentin a qualsevol prova de català nivell C1.
                    <br />
                    <br />
                    <strong>Format?</strong>
                    <br />
                    <br />
                    Intensiu de 2 mesos de duració, temps que estimen per assolir tots els
                    coneixements necessaris per aprovar l'examen
                    <br />
                    <br />
                    <strong>Mecànica del curs?</strong>
                    <br />
                    <br />
                    L'alumne pot iniciar el curs en qualsevol data.
                    <br />
                    <br />
                    Des del moment de la matriculació s'activen els 60 dies de duració.
                    <br />
                    <br />
                    Cada alumne disposa del temps que necessiti per fer tot el curs amb la finalitat
                    de millorar les parts de l'examen oral i escrita.
                    <br />
                    <br />
                    Tots els exercicis són auto corregibles, vídeos explicatius on l'alumne
                    practicarà autònomament sense cap tutor.
                    <br />
                    <br />
                    <strong>Contingut del curs?</strong>
                    <br />
                    <br />
                    Presentació del curs i 10 unitats formatives
                    <br />
                    <br />
                    <strong>U.F. 1. Tipologia de redaccions: (28 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1. Les tipologies de les redaccions... duració 2,19 min
                    <br />
                    <br />
                    ● Vídeo 2. Text descriptiu... duració 1,34 min
                    <br />
                    <br />
                    ● Vídeo 3. Text expositiu... duració 1,42 min
                    <br />
                    <br />
                    ● Vídeo 4. Text informatiu... duració 1,09 min
                    <br />
                    <br />
                    ● Vídeo 5. Text instructiu... duració 1,39 min
                    <br />
                    <br />
                    ● Vídeo 6. Text narratiu... duració 1,09 min
                    <br />
                    <br />
                    ● Vídeo 7. Text retòric... duració 1,15 min
                    <br />
                    <br />
                    ● Vídeo 8. Text carta... duració 2,02 min
                    <br />
                    <br />
                    ● Vídeo 9. Text correu electrònic... duració 2,06 min
                    <br />
                    <br />
                    ● Vídeo 10. Text article d'opinió... duració 2,32 min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 10,31 min
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Test de 15 preguntes autocorregibles.
                    <br />
                    <br />
                    <strong>U.F. 2. Omple els buits: (22 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1 Connectors ....duració 3,11 min
                    <br />
                    <br />
                    ● Vídeo 2 Connectors.... duració 4,25 min
                    <br />
                    <br />
                    ● Vídeo 3 Exercicis...duració 5,48 min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 8,30 min
                    <br />
                    <br />
                    ● Teoria ser/estar
                    <br />
                    <br />
                    ● Exercicis PDF ser/estar
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Exercici omplir els buits
                    <br />
                    <br />
                    ● Test de 15 preguntes auto corregibles.
                    <br />
                    <br />
                    <strong>U.F. 3. Derivació i adjectius: (16,25 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1 Derivació ....duració 3,12 min
                    <br />
                    <br />
                    ● Vídeo 2 Derivació ....duració 4,03 min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 5,08 min
                    <br />
                    <br />
                    ● Vídeo 3 Adjectius... duració...4,02 min
                    <br />
                    <br />
                    ● Exercicis amb adjectius A
                    <br />
                    <br />
                    ● Exercicis amb adjectius B
                    <br />
                    <br />
                    ● Exercicis amb adjectius C
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Test de 15 preguntes auto corregibles.
                    <br />
                    <br />
                    <strong>U.F. 4. Frases amb significat similar: (18 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1 Preposicions....duració 5,07 min
                    <br />
                    <br />
                    ● Vídeo 2 Pronoms relatius (2) ....duració 6,14 min
                    <br />
                    <br />
                    ● Vídeo 3 Exemples....duració 2,35 min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 3,17 min
                    <br />
                    <br />
                    ● Exercicis de preposicions
                    <br />
                    <br />
                    ● Exercicis de preposicions (2)
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Test de 15 preguntes autocorregibles.
                    <br />
                    <br />
                    <strong>U.F. 5. Barbarismes: (7,56 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1 Barbarismes... duració 1,56 min
                    <br />
                    <br />
                    ● Vídeo 1 Barbarismes... duració 3,19 min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 2,19 min
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Test de 15 preguntes auto corregibles.
                    <br />
                    <br />
                    <strong>U.F. 6. Frases fetes: (7,23 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1 Explicació... duració 2,52 min
                    <br />
                    <br />
                    ● Vídeo 2 Top 10 Frases fetes... 2,17 min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 3,05 min
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Test de 15 preguntes autocorregibles.
                    <br />
                    <br />
                    <strong>U.F. 7. Regles d'accentuació: (22 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1 Preàmbul ... duració 1,42 min
                    <br />
                    <br />
                    ● Vídeo 2 Accentuació ... duració 3,24 min
                    <br />
                    <br />
                    ● Vídeo 3 Monosíl·labs ... duració 3,16 min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 2,38 min
                    <br />
                    <br />
                    ● Vídeo 4 Perquè/Per què /Per a què... duració 5,22 min
                    <br />
                    <br />
                    ● Exercicis Per què/ Perquè/ Per a què
                    <br />
                    <br />
                    ● Vídeo 5 dièresi... duració 2,20 min
                    <br />
                    <br />
                    ● Exercicis dièresi
                    <br />
                    <br />
                    ● Vídeo 5 diftongs i hiats... duració 3,21min
                    <br />
                    <br />
                    ● Exercicis Diftongs i Hiats
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Test de 15 preguntes auto corregibles.
                    <br />
                    <br />
                    <strong>U.F. 8. Lectura: (8,15 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1 Lectura 1... duració 2,03 min
                    <br />
                    <br />
                    ● Vídeo 2 Lectura 2... duració 1,19 min
                    <br />
                    <br />
                    ● Vídeo 3 Lectura 3... duració 1,52 min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 2,55 min
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Test de 15 preguntes auto corregibles.
                    <br />
                    <br />
                    <strong>U.F. 9. Descripció: (11,50 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1 Explicació... duració 2,54 min
                    <br />
                    <br />
                    ● Vídeo 2 Exemple... duració 2,19 min
                    <br />
                    <br />
                    ● Vídeo 3 Exemple (2)... duració 4,14min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 2,05 min
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Test de 15 preguntes auto corregibles.
                    <br />
                    <br />
                    <strong>U.F. 10. Repàs: (16,50 min aprox)</strong>
                    <br />
                    <br />
                    ● Vídeo 1 Repàs... duració 12,44 min
                    <br />
                    <br />
                    ● Pòdcast de reforç... duració 4,07 min
                    <br />
                    <br />
                    ● Fitxa amb idees força
                    <br />
                    <br />
                    ● Test de 30 preguntes auto corregibles.
                    <br />
                    <br />
                    ● Examen final (A)
                    <br />
                    <br />
                    ● Examen final (B)
                    <br />
                    <br />
                    ● Examen final (C)
                    <br />
                    <br />
                    ● Plantilla examen oficial
                    <br />
                    <br />
                    Per un pagament únic de 49.99 euros
                    <br />
                    <br />
                    Comença avui mateix!
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/wOduVIE2mgg "
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        </ProductTemplate>
    );
};

export default Product;
*/
